<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="Formulir Filter Laporan Surat Setoran Retribusi Daerah" 
            class="panel panel-success"
        >
            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <!-- <div class="pull-right">
                                <b-button class="pull-right" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button>
                            </div> -->
                            <!-- <h6 class="mb-0">Tools</h6> -->
                            <em class="mb-0">
                                <strong>Form Filter Data SSRD</strong><br>
                                Laporan SSRD (Surat Setoran Retribusi Daerah) adalah laporan yang berisi realisasi 
                                pembayaran retribusi <u>berdasarkan tanggal bayar</u>, 
                                dan juga dapat difilter <u>berdasarkan metode bayar</u>.
                            </em>
                        </template>
                        
                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>IDENTITAS SKPD &amp; UPT</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Perangkat</label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.perangkat"
                                                v-model="params.perangkat_pd_id" @input="inputPerangkat(params.perangkat_pd_id)"
                                                placeholder="-Pilih-" :disabled="user.roles[0].name === 'Bendahara'"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Unit</label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.unit"
                                                v-model="params.unit_pd_id" placeholder="-Pilih-"
                                                :disabled="user.roles[0].name === 'Bendahara'"></v-select> -->
                                            <v-select label="nama" :reduce="nama => nama.id" :options="Perangkat.unit"
                                                v-model="params.unit_pd_id" placeholder="-Pilih-"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>PERIODE SSRD</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Berdasarkan <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <v-select 
                                                label="nama" :reduce="nama => nama.id" 
                                                :options="optionPeriode.kolom" v-model="params.periode_kolom" 
                                                placeholder="-Pilih Periode Berdasarkan-"></v-select>
                                            <em v-if="errors.hasOwnProperty('periode_kolom')"
                                                class="form-text text-danger">{{ errors.periode_kolom[0] }}</em>
                                        </div>
                                        <em>
                                        <ol class="row mb-0">
                                            <li>
                                                <!-- <b>Tanggal Bayar</b>, tanggal dibayarkannya biaya retribusi berdasarkan SKRD/STRD oleh Wajib Retribusi melalui tempat pembayaran yang sah -->
                                                <b>Tanggal Bayar</b>, tanggal disaat Wajib Retribusi membayarkan biaya retribusi berdasarkan SKRD/STRD melalui tempat pembayaran yang sah.
                                            </li>
                                            <li>
                                                <!-- <b>Tanggal Setor</b>, tanggal disetorkannya biaya retribusi berdasarkan SKRD/STRD/STSRD oleh Wajib Retribusi / Bendahara Penerimaan ke Bank RKUD -->
                                                <b>Tanggal Setor</b>, tanggal disaat Wajib Retribusi membayarkan biaya retribusi berdasarkan SKRD/STRD langsung ke Bank RKUD.<br>
                                                Atau tanggal disaat Bendahara Penerimaan menyetorkan biaya retribusi berdasarkan STSRD ke Bank RKUD
                                            </li>
                                        </ol>
                                        </em>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Pilih Periode</label>
                                <div class="col-md-4">
                                    <custom-date-picker 
                                        v-model="params.tanggal_dari" 
                                        @on-input="inputTanggalDari" 
                                        @decrease="evalDate(params, 'tanggal_dari', 'decrease')" 
                                        @increase="evalDate(params, 'tanggal_dari', 'increase')"
                                    />
                                    <periode-button
                                        @day-range="evalDateRange('harian')"
                                        @week-range="evalDateRange('mingguan')"
                                        @month-range="evalDateRange('bulanan')"
                                     />
                                    <custom-date-picker 
                                        v-model="params.tanggal_sampai" 
                                        :min="datePicker.min" 
                                        @decrease="evalDate(params, 'tanggal_sampai', 'decrease')" 
                                        @increase="evalDate(params, 'tanggal_sampai', 'increase')"
                                    />
                                </div>
                            </div>
                            <!-- <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Tahun <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <v-select :options="tahun" v-model="params.tahun" placeholder="-Pilih Tahun-"></v-select>
                                            <em v-if="errors.hasOwnProperty('tahun')"
                                                class="form-text text-danger">{{ errors.tahun[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Bulan <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-3"> -->
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="bulan" v-model="params.bulan"
                                                placeholder="-Pilih Bulan-"></v-select> -->
                                            <!-- <v-select label="nama" :reduce="nama => nama.id" :options="bulanOptions" v-model="params.bulan"
                                                placeholder="-Pilih Bulan-"></v-select>
                                            <em v-if="errors.hasOwnProperty('bulan')"
                                                class="form-text text-danger">{{ errors.bulan[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <h5 class="font-weight-bold"><u>RINCIAN PEMBAYARAN</u></h5>
                                </div>
                            </div>
                            <div class="form-group row mb-1 mx-1">
                                <label class="col-md-2 col-form-label">Metode Bayar <span class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <v-select 
                                                label="nama" :reduce="nama => nama.id" 
                                                :options="optionPembayaran.metode" v-model="params.metode_bayar_id" 
                                                placeholder="-Pilih Metode Bayar-"></v-select>
                                            <em v-if="errors.hasOwnProperty('metode_bayar_id')"
                                                class="form-text text-danger">{{ errors.metode_bayar_id[0] }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                        <!-- end FILTER -->

                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="getDataLaporan" class="">
                                    <i class="fa fa-eye"></i> Lihat
                                </b-button>
                                <!-- <b-button variant="secondary" @click="printDoc()" class="mr-1">
                                    <i class="fa fa-print"></i> Print
                                </b-button> -->
                                <b-button variant="primary" @click="showPdf" class="mx-1">
                                    <i class="fa fa-print"></i> Print .pdf
                                </b-button>
                                <b-button variant="primary" @click="showPdf" disabled>
                                    <i class="fa fa-print"></i> Print .pdf (Detail)
                                </b-button>
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->
        </panel>
        <!-- end of panel -->

        <!-- model loader
        -------------------------------------------------- -->
        <b-modal v-model="showLoader" id="modalLoader" hide-footer hide-header no-close-on-backdrop centered size="sm">
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>

        <!-- hasil filter data laporan
        -------------------------------------------------- -->
        <panel v-if="dataLaporan"
            title="Laporan Surat Ketetapan Retribusi Daerah" 
            class="panel panel-success"
        >
        
            <div class="row" id="printArea">
            <div class="col-sm-12">

                <!-- KOP SURAT -->
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <td width='15%' align='center'
                                style='background-color:#FFF; border-right-color:#FFF; border-left-color:#FFF;'>
                                <img :src="logoPemprovsu" alt="logo" width="50">
                            </td>
                            <td width='70%' class="text-center align-middle"
                                style='background-color:#FFF; border-right-color:#FFF'>
                                    <h5 class='title' style='margin-bottom:0px;'>
                                        <strong>PEMERINTAH PROVINSI SUMATERA UTARA</strong>
                                    </h5>
                                    <h6 class='title' style='margin-bottom:-5px;'>
                                        <strong>{{ dataLaporan.header_data.pemerintah_daerah.perangkat_pd.nama.toUpperCase() }}</strong>
                                    </h6>
                                    <h6 class="mt-2" style='margin-bottom:-2px;'>
                                        <small style="font-size:12px; line-height: 1.3;">
                                            {{ dataLaporan.header_data.pemerintah_daerah.unit_pd.nama.toUpperCase() }}<br>
                                        </small>
                                    </h6>
                            </td>
                            <td width='15%' align='center'
                                style='background-color:#FFF; border-right-color:#FFF; border-left-color:#FFF;'>
                            </td>
                        </tr>
                    </thead>
                </table>
                <!-- end KOP SURAT -->

                <!-- JUDUL SURAT -->
                <table id="" class="table table-bordered table-sm">
                    <thead>
                    </thead>
                    <tbody style="font-size:11px; line-height: 1.3;">
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <tbody style="font-size:11px;">
                                        <tr>
                                            <td colspan="5" class="py-0 px-1" align="center">
                                                <h6 class="title m-0" style="font-size:14px;">
                                                    <strong>
                                                        LAPORAN SURAT SETORAN RETRIBUSI DAERAH<br />
                                                        (SSRD)
                                                    </strong>
                                                </h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <tbody style="font-size:11px;">
                                        <tr>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Perangkat PD
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                <strong>{{ dataLaporan.data.filter.pemerintah_daerah.perangkat_pd.nama }}</strong>
                                            </td>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Periode
                                            </td>
                                            <td colspan="" class="py-0 px-1" width="150px">
                                                <strong>
                                                    {{ dataLaporan.data.filter.periode.tahun }}/{{ dataLaporan.data.filter.periode.bulan }}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="" class="py-0 px-1" width="100px">
                                                Unit PD
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                <strong>{{ dataLaporan.data.filter.pemerintah_daerah.unit_pd.nama }}</strong>
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                Metode Bayar
                                            </td>
                                            <td colspan="" class="py-0 px-1">
                                                <strong>{{ dataLaporan.data.filter.pembayaran.metode.nama }}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                        </tr>
                        <tr>
                            <td class="p-0">
                                <table id="" class="m-0 table table-sm">
                                    <thead>
                                        <tr class="text-center">
                                            <td class="align-middle py-0 px-1" width="30px" rowspan="2">
                                                <strong>No.</strong>
                                            </td>
                                            <td class="text-center" rowspan="2" width="60px">
                                                <strong>#</strong>
                                            </td>
                                            <td v-role:any="'Administrator|Maintenance'" class="align-middle py-0 px-1" rowspan="2">
                                                <strong>OPD<br>Unit PD</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1" rowspan="2">
                                                <strong>NPWRD</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1" rowspan="2">
                                                <strong>No. / Tgl<br>SPTRD</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1" rowspan="2">
                                                <strong>No. / Tgl<br>Kode Bayar</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1" colspan="2">
                                                <strong>Rincian Pembayaran</strong>
                                            </td>
                                            <!-- <td class="align-middle py-0 px-1" width="100px" rowspan="2">
                                                <strong>Nilai Kode Bayar<br>(IDR)</strong>
                                            </td> -->
                                            <td class="align-middle py-0 px-1" width="100px" rowspan="2">
                                                <strong>Jumlah Bayar<br>(IDR)</strong>
                                            </td>
                                        </tr>
                                        <tr class="text-center">
                                            <td class="align-middle py-0 px-1">
                                                <strong>Metode</strong>
                                            </td>
                                            <td class="align-middle py-0 px-1">
                                                <strong>Keterangan</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, index) in dataLaporan.data.ssr">
                                            <tr :key="index">
                                                <td class="py-0 px-1 text-right" width="30px">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="py-0 px-1 text-center">
                                                    <div class="h-full m-5">
                                                        <button class="btn btn-sm btn-info" @click="expandRowDetail(index)" style="padding: 0.2rem 0.4rem !important;">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td v-role:any="'Administrator|Maintenance'" class="py-0 px-1">
                                                    {{ item.spt.perangkat_pd.nama }}<br>
                                                    {{ item.spt.unit_pd.nama }}
                                                </td>
                                                <td class="py-0 px-1">
                                                    {{ item.spt.npwrd.no_pokok }}<br>
                                                    <div v-if="item.spt.npwrd.bentuk_badan">
                                                        {{ item.spt.npwrd.bentuk_badan.kode }}
                                                    </div>
                                                    {{ item.spt.npwrd.nama }}
                                                </td>
                                                <td class="py-0 px-1">
                                                    {{ item.spt.no_spt }}<br>
                                                    {{ item.bayar_at }}
                                                </td>
                                                <td class="py-0 px-1">
                                                    {{ item.no_sts }}<br>
                                                    {{ item.tgl_sts }}
                                                </td>
                                                <td class="py-0 px-1">
                                                    Metode Bayar: {{ item.metode_bayar }} <br >
                                                    Tanggal: {{ item.diterima_tgl }}
                                                </td>
                                                <td class="py-0 px-1" width="300px">
                                                    {{ item.keterangan }}
                                                </td>
                                                <!-- <td class="py-0 px-1 text-right">
                                                    {{ item.nilai_terutang.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                </td> -->
                                                <td class="py-0 px-1 text-right">
                                                    {{ item.bayar_nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                </td>
                                            </tr>
                                            <Transition :key="item.id"
                                                enter-active-class="enter-from"
                                                enter-to-class="enter-to"
                                                leave-active-class="leave-active"
                                                mode="out-in"
                                            >
                                                <template>
                                                    <tr v-if="expandRow[index]">
                                                        <td colspan="9">
                                                            <b-table-simple
                                                                small
                                                                hover
                                                                bordered
                                                                responsive
                                                            >
                                                                <b-thead head-variant="light">
                                                                    <b-tr>
                                                                        <b-th>No.</b-th>
                                                                        <b-th>Rincian Jasa/Pelayanan</b-th>
                                                                        <b-th class="text-right">Tarif (Rp./%)</b-th>
                                                                        <b-th class="text-center">Qty</b-th>
                                                                        <b-th class="text-right">Jumlah (IDR)</b-th>
                                                                        <b-th>Keterangan</b-th>
                                                                    </b-tr>
                                                                </b-thead>
                                                                <b-tbody>
                                                                    <b-tr v-for="(rincian, index) in item.spt.spt_detail" :key="rincian.id">
                                                                        <b-td class="py-0 px-1 text-center">
                                                                            {{ index + 1 }}
                                                                        </b-td>
                                                                        <b-td class="py-0 px-1">
                                                                            <div v-html="rincian.ret_rincian"></div>
                                                                        </b-td>
                                                                        <b-td class="py-0 px-1 text-right">
                                                                            {{ conversion.run(rincian.tarif) }}
                                                                        </b-td>
                                                                        <b-td class="py-0 px-1 text-right">
                                                                            {{ rincian.qty }}
                                                                        </b-td>
                                                                        <b-td class="py-0 px-1 text-right">
                                                                            {{ conversion.run(rincian.nilai) }}
                                                                        </b-td>
                                                                        <b-td class="py-0 px-1">
                                                                            {{ rincian.keterangan }}
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </Transition>
                                        </template>
                                        <tr>
                                            <td class="py-0 px-1 text-right"></td>
                                            <!-- <td v-role:any="'Administrator|Maintenance'" colspan="7" class="py-0 px-1">
                                                <strong>Total Nilai SSRD</strong>
                                            </td>
                                            <td v-role:unless="'Administrator'" colspan="6" class="py-0 px-1">
                                                <strong>Total Nilai SSRD</strong>
                                            </td> -->
                                            <td :colspan="user_role === 'Administrator' || user_role === 'Maintenance' ? 7 : 6" class="py-0 px-1">
                                                <strong>Total Nilai SSRD</strong>
                                            </td>
                                            <!-- <td class="py-0 px-1 text-right">
                                                <strong>
                                                    {{ dataLaporan.data.total.nilai_terutang.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                </strong>
                                            </td> -->
                                            <td class="py-0 px-1 text-right">
                                                <strong>
                                                    {{ dataLaporan.data.total.bayar_nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>


                    </tbody>
                </table>


                <!-- <pre>{{ dataLaporan }}</pre> -->
                

            </div>
            </div>

        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import logoPemprovsu from '@/assets/images/logoSumut.png'

import HRetribusi from "@/components/npwr/HRetribusi.vue";
import axios from "axios";
import { mapState } from "vuex";
import hapusStorage from '@/helper/hapusLocalStore'
import conversion from '@/helper/konversi.js'
import moment from 'moment'
import PeriodeButton from '@/components/custom/Periode/PeriodeButton.vue';
import CustomDatePicker from '@/components/custom/Periode/CustomDatePicker.vue';

export default {
    mixins: [hapusStorage],
    components: {
        HRetribusi,
        PeriodeButton,
        CustomDatePicker
    },
    data() {
        return {
            conversion,
            logoPemprovsu: logoPemprovsu,
            user: JSON.parse(localStorage.getItem('user')),
            user_role: JSON.parse(localStorage.getItem('roles'))[0],
            optionPeriode:
            {
                kolom: [
                    {
                        id: "diterima_tgl",
                        nama: 'Tanggal Bayar'
                    },
                    {
                        id: "disetorkan_tgl",
                        nama: 'Tanggal Setor'
                    }
                ]
            },
            optionPembayaran: 
            {
                metode: [
                    {
                        id: "all",
                        nama: 'Semua Metode Bayar'
                    },
                    {
                        id: 1,
                        nama: 'Bendahara Penerimaan'
                    },
                    // {
                    //     id: 2,
                    //     nama: 'Bank Sumut'
                    // },
                    {
                        id: 3,
                        nama: 'QRIS Statis'
                    }
                ]
            },
            params: new Form({
                perangkat_pd_id: "",
                unit_pd_id: "",
                periode_kolom: "",
                metode_bayar_id: "",
                tanggal_dari: '',
                tanggal_sampai: '',
            }),
            dataLaporan: '',
            bulanOptions: [
                {
                    nama: "Januari",
                    id: 1,
                },
                {
                    nama: "Februari",
                    id: 2,
                },
                {
                    nama: "Maret",
                    id: 3,
                },
                {
                    nama: "April",
                    id: 4,
                },
                {
                    nama: "Mei",
                    id: 5,
                },
                {
                    nama: "Juni",
                    id: 6,
                },
                {
                    nama: "Juli",
                    id: 7,
                },
                {
                    nama: "Agustus",
                    id: 8,
                },
                {
                    nama: "September",
                    id: 9,
                },
                {
                    nama: "Oktober",
                    id: 10,
                },
                {
                    nama: "November",
                    id: 11,
                },
                {
                    nama: "Desember",
                    id: 12,
                },
            ],
            bulan: [],
            tahun: [],
            showLoader: false,
            isLoading: false,
            pdfUrl: "",
            errors: [],
            roles: [
                "Bendahara"
            ],
            expandRow: [],
            datePicker: {
                min: null,
            }
        };
    },
    computed: {
        ...mapState(["Perangkat"]),
    },
    mounted() {
        this.$store.dispatch("Perangkat/getPerangkat");
        if (this.roles.includes(this.user_role)) {
            // data pegawai
            this.getPegawai(this.user.pegawai_id)
        }
        // this.getTahun();
        // mount tanggal hari ini
        this.getTodayDate()
    },
    methods: {
        inputPerangkat(id) {
            if (id) {
                this.$store.dispatch("Perangkat/getUnit", id);
            } else {
                this.params.unit_pd_id = ''
            }
        },
        // Tahun berjalan
        getTahun() {
            const now = new Date();
            this.tahun.push(String(now.getFullYear()-1));
            this.tahun.push(String(now.getFullYear()));
            this.bulanOptions.forEach((items) => {
                if (now.getMonth() + 1 - 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                } else if (now.getMonth() + 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                } else if (now.getMonth() + 1 + 1 === parseInt(items.id)) {
                    this.bulan.push(items);
                }
            });
            this.params.periode_kolom    = 'diterima_tgl';
            this.params.bulan           = now.getMonth() + 1;
            this.params.tahun           = String(now.getFullYear());
            this.params.metode_bayar_id = "all"
        },
        showPdf() {
            this.getPdf();
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // get data laporan ...
        getDataLaporan() {
            this.showLoader = true
            axios
                .get("/api/laporan/transaksi/ssrd/by-periode", {
                    params: this.params
                })
                .then((response) => {
                    this.showLoader = false
                    this.dataLaporan = response.data
                })
                .catch((error) => {
                    this.showLoader = false
                    this.dataLaporan = ''
                    this.$swal({
                        icon: "error",
                        title: "Terjadi Masalah.",
                        text: error.response.data.status.message,
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonClass: "btn btn-primary",
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 6000
                    });
                });
        },
        // view pdf
        getPdf() {
            this.showLoader = true
            axios
                .get("/api/view-pdf/laporan/transaksi/ssrd/by-periode", {
                    params: this.params,
                    responseType: "arraybuffer",
                    timeout: 20000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.closeModal();
                    this.showLoader = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    }
                });
        },
        // data pegawai
        getPegawai (id) {
            axios.get("/api/data-induk/kepegawaian/pegawai/" + id).then(response => {
                const items = response.data.data
                this.params.unit_pd_id = items.unit_pd_id
                this.params.perangkat_pd_id = items.unit_pd.perangkat_pd_id
                this.$store.dispatch("Perangkat/getUnit", this.params.perangkat_pd_id)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        },
        // expand table
        expandRowDetail: function (index) {
            if (this.expandRow[index] == null) {
                this.$set(this.expandRow, index, true)
            } else {
                if (this.expandRow[index]) {
                    this.$set(this.expandRow, index, false)
                } else {
                    this.$set(this.expandRow, index, true)
                }
            }
        },
        inputTanggalDari: function (date) {
            this.datePicker.min = date
        },
        getTodayDate: function () {
            const now = moment().format('YYYY-MM-DD')
            this.inputTanggalDari(now)
            this.params.tanggal_dari = this.params.tanggal_sampai = now
            this.params.metode_bayar_id = "all"
            this.params.periode_kolom    = 'diterima_tgl';
        },
        evalDate: function (params, dateVar, operator) {
            switch(operator) {
                case 'decrease':
                    params[dateVar] = moment(params[dateVar]).subtract(1, 'days').format('YYYY-MM-DD')
                    break
                default:
                    params[dateVar] = moment(params[dateVar]).add(1, 'days').format('YYYY-MM-DD')
                    break
            }
        },
        evalDateRange: function (range) {
            const dateNow = new Date()
            switch(range) {
                case 'harian':
                    this.params.tanggal_dari = dateNow
                    this.params.tanggal_sampai = dateNow
                    break
                case 'mingguan':
                    this.params.tanggal_dari = moment(this.params.tanggal_dari).startOf('week').format('YYYY-MM-DD')
                    this.params.tanggal_sampai = moment(this.params.tanggal_dari).endOf('week').format('YYYY-MM-DD')
                    break
                case 'bulanan':
                    this.params.tanggal_dari = moment().startOf('month').format('YYYY-MM-DD')
                    this.params.tanggal_sampai = moment().endOf('month').format('YYYY-MM-DD')
            }
        }
    },
};
</script>

<style scoped>
    .custom-border {
        border-top: 1px solid rgb(78, 77, 77);
    }
    .enter-from {
        transform: translateY(-10px);
        opacity: 0;
        transition: all ease-in 0.3s;
    }
    .enter-to {
        opacity: 1;
        transform: translateY(0px);
        transition: all ease-in 0.3s;
    }
    .leave-active {
        opacity: 0;
        transform: translateY(-10px);
        transition: all ease-in 0.2s;
    }
</style>